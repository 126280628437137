<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline" label-suffix="：" ref="form">
                <el-form-item label="活动名称">
                    <el-input v-model="queryParams.name" placeholder="请输入活动名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="已下架" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="位置" prop="position">
                    <el-select v-model="queryParams.position" placeholder="请选择位置" @change="changeQuery" clearable>
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增套装</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">

                <el-table-column label="id" prop="id" align="center" width="80"></el-table-column>
                <el-table-column label="活动名称" prop="name" align="center" width="180"></el-table-column>
                <el-table-column label="logo" align="center" width="80" prop="logo">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" fit="cover" :class="`column${scope.$index}`" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="专场描述" prop="desc" align="center" width="400"></el-table-column>
                <el-table-column label="位置" prop="position_name" align="center" width="150"></el-table-column>
                <el-table-column label="开始时间" prop="stime" width="150" align="center"></el-table-column>
                <el-table-column label="结束时间" prop="etime" width="150" align="center"></el-table-column>
                <el-table-column label="活动类型" prop="ty" align="center" width="100">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">n件n元</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 2"> n件n折</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="能否使用优惠券" prop="is_coupon" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.is_coupon === 1">不可用</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_coupon === 2">可用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 3">已下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="suitGoods(scope.row.id)">配置商品</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Url
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            menuList: ['营销', '套装购'],
            queryParams: {
                page: 1,
                pagesize: 10
            },
            positionList: [],
            loading: false,
            pageInfo: {
            },
            confirmVisible: false,
            confirmContent: '',
            form: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            }

        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        deal.getconf('suit', this)
    },
    watch: {
        $route(to, from) {
            let ArticleEdit = from.fullPath.indexOf('/activity-SuitforEdit')
            let ArticleAdd = from.fullPath.indexOf('/activity-Suitforadd')
            if (ArticleEdit !== -1 || ArticleAdd !== -1) {
                this.loadData()
            }
        }
    },

    methods: {

        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        loadData() {
            this.loading = true
            request.get('/goods/suit/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.stime = deal.timestamp(val.stime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增
        add() {
            this.$router.push({ path: '/activity-Suitforadd' })
        },
        //编辑
        edit(index, row) {
            this.$router.push({ path: '/activity-SuitforEdit', query: { id: row.id } })
        },
        //状态
        state(row, state) {
            this.confirmVisible = true;
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.stime = deal.timestamp(this.form.stime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        // 套装商品
        suitGoods(id) {
            this.$router.push({ path: '/activity-SuitGoods', query: { id: id } })
        },
        // 确定下线
        stateData(row, state) {
            request.post('/goods/suit/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
</style>